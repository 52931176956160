.parentCompany {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top:15px;
    
    background-color: white;
  }

  .zailabClass{
    order:-1;
    // margin-right: 200px;
  }

.aboutContainer{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:30px;
    order:1;
}

.aboutC{
font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
color:#1D4480;
margin-bottom: 0;
}

.descriptionC{
font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 21px;
color:#1D4480;
}

.click_presentation{
    cursor: pointer;
    font-weight: 600;
    color:#0980A7;

    a:link {
        color:#0980A7;
    }

    a:visited{
        color:#782282;
    }
}

.sub_description{
font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
color: #1D4480;
margin: 0;
margin-top:15px;

}

.about_lists{
font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 21px;
color: #1D4480;
}



.buttonC{
align-self: center;
}


button{

    width: 147.13px;
    height: 36px;
    margin-top: 1%;
    background: linear-gradient(272.35deg, rgba(22, 66, 130, 0.5) 4.47%, rgba(0, 149, 198, 0.5) 105.38%);
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    border-radius: 50px;
    border: none;
    // font
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 0px;
    color: white;
    
}

@media screen and (orientation: landscape) {
    // mobile landscape

        .aboutContainer{
            padding: 30px;
        }

        .buttonC{
            align-self:flex-start;
            }
    }


    @media (max-width: 2800px) and (min-width: 1400px)  {

        .parentCompany {
            display: flex;
            flex-direction: row;
            padding-top:15px;
            background-color: white;
           
            justify-content: space-between;
          }
        
          .zailabClass{
            // padding-top:-580px;
            margin-right: 6%;
            margin-bottom:10%;
            order:1;
          }

        .aboutContainer{
            order:-1;
            margin-left:6%;
            // padding:0 100px;
        } 

        .buttonC{
                button{
                    width: 272px;
                    height: 64px; 
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;  
                }
            }
            .descriptionC,.about_lists{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0.01em;
                color: #164282;
            }

            .aboutC{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 48px;
                color: #164282;
            }

            .sub_description{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 38px;
                color: #164282;
                margin-top:16px;
            }
    }
