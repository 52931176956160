.containerJ{
    box-sizing: border-box;
    border: 2px solid #0095C6;
    border-radius: 30px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    .headerJ{
        .headerJ-parent{
            display: flex;
            flex-direction: row;
            align-items: center;
            .iconJ{
                width: 80px;
                height: 90px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                .zailabIcon2{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    background-image: url(../../assets/LogoZ.png);
                    background-size: cover;
                    background-position: center;
                }
            }
            .title-parent{
                margin-left: 2rem;
                .title{
                    margin: 0;
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 38px;
                    color: #164282;
                }
                .description{
                    margin: 0;
                    padding: 0;
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.2rem;
                    letter-spacing: 0.01em;
                    color: #164282;
                }
            }
            .desktop-only{
                display: block;
            }
        }
    }
    .descriptionJ{
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        .mobile-only{
            display: none;
        }
        .time-date-desc{
            margin-right: 2rem;
            .time-date-desc-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.01em;
                color: #0095C6;
            }
        }
        .button-parent{
            align-self: center;
        }
    }
}

.secondDesc{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1D4480;
}

.buttonJ{
    width: 154px;
    height: 36px;
    margin-top: 1%;
    background: linear-gradient(272.35deg, rgba(22, 66, 130, 0.5) 4.47%, rgba(0, 149, 198, 0.5) 105.38%);
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    border-radius: 50px;
    border: none;
    // font
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 0px;
    color: white;
    margin-left: 2rem
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait){
    .containerJ{
        margin-top: 5%;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 5%;
        padding-bottom: 5%;
        width: 90%;
        .headerJ{
            margin-bottom: .5rem;
            .headerJ-parent{
                display: flex;
                align-items: center;
                .iconJ{
                    height: unset;
                }
                .title-parent{
                    margin-left: 1rem !important;
                    font-family: 'Work Sans' !important;
                    font-style: normal !important;
                    font-weight: 600 !important;
                    font-size: 22px !important;
                    line-height: 26px !important;   
                    display: flex !important;
                    align-items: center !important;
                    color: #164282;
                    .title{
                        font-family: 'Work Sans' !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        font-size: 22px !important;
                        line-height: 26px !important;
                        /* or 118% */
    
                        display: flex !important;
                        align-items: center !important;
                    }
                }
            }
        }
        .desktop-only{
            display: none !important;
        }
        .descriptionJ{
            flex-direction: column;
            align-items: flex-start;
            .mobile-only{
                display: block !important;
            }
            .qualifications{
                margin-bottom: .5rem;
            }
            p{
                margin: 0;
            }
            .qualifications-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #164282;
                text-align: start;
            }
            .time-date-desc{
                margin-right: unset;
                .time-date-desc-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #0095C6;
                }
            }
            .buttonJ{
                margin-left: unset;
                align-self: center;
                margin-top: 1.5rem
            }
        }
    }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape){
    .containerJ{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        flex-direction: column;
        padding-left: 1.5em;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        .headerJ{
            margin-bottom: .5rem;
            .headerJ-parent{
                display: flex;
                align-items: center;
                .iconJ{
                    width: 33px;
                    height: 37px;
                }
                .title-parent{
                    margin-left: 1rem !important;
                    font-family: 'Work Sans' !important;
                    font-style: normal !important;
                    font-weight: 600 !important;
                    font-size: 22px !important;
                    line-height: 26px !important;   
                    display: flex !important;
                    align-items: center !important;
                    color: #164282;
                    .title{
                        font-family: 'Work Sans' !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        font-size: 22px !important;
                        line-height: 26px !important;
                        /* or 118% */
    
                        display: flex !important;
                        align-items: center !important;
                    }
                }
            }
        }
        .desktop-only{
            display: none !important;
        }
        .descriptionJ{
            flex-direction: column;
            align-items: flex-start;
            position: relative !important;
            .mobile-only{
                display: block !important;
            }
            .qualifications{
                margin-bottom: .5rem;
            }
            p{
                margin: 0;
            }
            .qualifications-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #164282;
                text-align: start;
            }
            .time-date-desc{
                margin-right: unset;
                .time-date-desc-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #0095C6;
                }
            }
            .button-parent{
                position: absolute !important;
                right: 0 !important;
                bottom: 0 !important;
            }
        }
    }
}

/* Styles for portrait tablet */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .containerJ{
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        flex-direction: column;
        padding-left: 1.5em;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        width: 90% !important;
        .headerJ{
            margin-bottom: .5rem;
            .headerJ-parent{
                display: flex;
                align-items: center;
                .iconJ{
                    width: 33px;
                    height: 37px;
                }
                .title-parent{
                    margin-left: 1rem !important;
                    font-family: 'Work Sans' !important;
                    font-style: normal !important;
                    font-weight: 600 !important;
                    font-size: 22px !important;
                    line-height: 26px !important;   
                    display: flex !important;
                    align-items: center !important;
                    color: #164282;
                    .title{
                        font-family: 'Work Sans' !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        font-size: 22px !important;
                        line-height: 26px !important;
                        /* or 118% */
    
                        display: flex !important;
                        align-items: center !important;
                    }
                }
            }
        }
        .desktop-only{
            display: none !important;
        }
        .descriptionJ{
            flex-direction: column;
            align-items: flex-start;
            position: relative !important;
            .mobile-only{
                display: block !important;
            }
            .qualifications{
                margin-bottom: .5rem;
            }
            p{
                margin: 0;
            }
            .qualifications-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.01em;
                color: #164282;
                text-align: start;
            }
            .time-date-desc{
                margin-right: unset;
                .time-date-desc-text{
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #0095C6;
                }
            }
            .button-parent{
                position: absolute !important;
                right: 0 !important;
                bottom: 0 !important;
            }
        }
    }
}

/* Styles for landscape tablet */
@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape){
    .containerJ{
        width: 90% !important;
    }
}