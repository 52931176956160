
.ratingStar{
    margin-right: .5rem !important;
}
.ratingStarFilled{
    margin-right: .5rem !important;
}



/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait){
    .ratingStar{
        margin-right: .1rem !important;
        width: 15.24px !important;
        height: 14.58px !important;
    }
    .ratingStarFilled{
        margin-right: .1rem !important;
        width: 15.24px !important;
        height: 14.58px !important;
    }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape){
    .ratingStar{
        margin-right: .1rem !important;
        width: 15.24px !important;
        height: 14.58px !important;
    }
    .ratingStarFilled{
        margin-right: .1rem !important;
        width: 15.24px !important;
        height: 14.58px !important;
    }
}