.jobs-container {
  .jobs-landing {
    .hero-image-parent {
      background-image: url(../../assets/jobs-hero.png);
      background-size: cover;
      background-position: center;
      height: 100vh;
      width: 100vw;
      .parent {
        position: absolute;
        width: fit-content;
        height: 6.25rem;
        left: 50%;
        transform: translate(-50%);
        // top: 3.631rem;
        top: 40px;
        text-align: center;
        .logo-and-text {
          margin-bottom: 3.174rem;
          // width: 6.25rem;
          height: auto;
        }
        .phrase-1 {
          margin: 0;
          padding: 0;
          text-align: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 3.5rem;
          line-height: 3.75rem;
          color: #164282;
          text-transform: uppercase;
        }
        .phrase-2 {
          margin: 0;
          padding: 0;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 6.25rem;
          line-height: 6.5rem;
          text-align: center;
          text-transform: uppercase;
          background: linear-gradient(
            133.84deg,
            #164282 3%,
            #164282 45.03%,
            #0095c6 84.51%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
        .phrase-3 {
          margin: 0;
          padding: 0;
          padding-top: 2.625rem;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 1.875rem;
          line-height: 2.563rem;
          text-align: center;
          color: #164282;
        }
      }
    }
  }
  .main-landing {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    .open-jobs-positions-title {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      text-transform: uppercase;
      color: #164282;
    }
    .locations {
      display: flex;
      flex-direction: row;
      align-items: center;

      .locations-item {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        margin-left: 1.233rem;
        width: 120px;
        height: 33px;
        color: #164282;
        &.pill {
          text-align: center;
          padding: 10px;
          gap: 10px;
          border: 3px solid #0095c6;
          border-radius: 50px;
          color: #0095c6;
          &.active {
            background-color: #0095c6 !important;
            color: #ffffff;
          }
        }
      }
    }
  }
  .footer {
  }
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait) {
  .jobs-container {
    .jobs-landing {
      .hero-image-parent {
        background-image: url(../../assets/mobile/jobs-hero.png) !important;
        background-size: cover;
        background-position: center;
        height: 68vh;
        width: auto;
        .parent {
          top: 1.75rem;
          height: fit-content;
          width: 100%;
          .logo-and-text {
            margin-bottom: 1.406rem;
            width: 6.25rem !important;
            height: auto !important;
          }
          .phrase-1 {
            font-family: "Work Sans";
            font-style: normal;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.875rem;
            text-align: center;
            text-transform: uppercase;
            color: #164282;
          }
          .phrase-2 {
            font-size: 2.75rem;
            line-height: 3rem;
            background: linear-gradient(
              185.7deg,
              #164282 32.49%,
              #0095c6 69.64%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
          .phrase-3 {
            display: flex;
            align-items: center;
            width: fit-content;
            margin: auto;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding-top: 0.75rem;
          }
        }
      }
    }
    .main-landing {
      display: flex;
      width: 90%;
      margin: auto;
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      margin-top: 0rem;
      .open-jobs-positions-title {
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #1d4480;
      }
      .locations {
        display: flex;
        flex-direction: row;
        align-items: center;

        .locations-item {
          width: 75.73px;
          height: 15px;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #1d4480;
          width: unset;

          &.pill {
            width: 75.73px;
            height: 15px;
            left: 108.55px;
            top: 0px;
            border-radius: 50px;
            font-family: "Work Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #1d4480;
            &.active {
              background-color: #0095c6 !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape) {
  .jobs-container {
    .hero-image-parent {
      background-image: url(../../assets/mobile-landscape/jobs-hero.png) !important;
      .parent {
        top: 2.75rem !important;
        .logo-and-text {
          margin-bottom: 0.357rem !important;
          width: 6.25rem !important;
          height: auto !important;
        }
        .phrase-1 {
          font-family: "Poppins" !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 1.5rem !important;
          line-height: 1.875rem !important;
        }
        .phrase-2 {
          font-family: "Poppins" !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 2.75rem !important;
          line-height: 2.75rem !important;
          text-align: center !important;
          text-transform: uppercase !important;

          background: linear-gradient(
            168.54deg,
            #164282 19.51%,
            #0095c6 76.58%
          ) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
          background-clip: text !important;
          text-fill-color: transparent !important;
        }
        .phrase-3 {
          font-family: "Work Sans" !important;
          font-style: normal !important;
          font-weight: 500 !important;
          font-size: 0.875rem !important;
          line-height: 1rem !important;
          text-align: center !important;
          color: #164282 !important;
          padding-top: unset !important;
        }
      }
    }
    .main-landing {
      // background-color: red;
      display: flex;
      width: 90%;
      margin: auto;
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      margin-top: 0rem;
      .open-jobs-positions-title {
        margin: 0;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #1d4480;
      }
      .locations {
        display: flex;
        flex-direction: row;
        align-items: center;

        .locations-item {
          width: 75.73px;
          height: 15px;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #1d4480;
          width: unset;

          &.pill {
            width: 75.73px;
            height: 15px;
            left: 108.55px;
            top: 0px;
            border-radius: 50px;
            font-family: "Work Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #1d4480;
            &.active {
              background-color: #0095c6 !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

/* Styles for portrait tablet */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .jobs-container {
    .hero-image-parent {
      background-image: url(../../assets/mobile/jobs-hero.png) !important;
      .parent {
        width: 100% !important;
        .logo-and-text {
          width: unset !important;
        }
        .phrase-1 {
          font-size: 2.5rem !important;
        }
        .phrase-2 {
          font-size: 4.25rem !important;
        }
      }
    }
    .main-landing {
      flex-direction: column;
      .open-jobs-positions-title {
        margin: 0;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
      }
      .locations {
        justify-content: center;
      }
    }
  }
}

/* Styles for landscape tablet */
@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
  .jobs-container {
    .hero-image-parent {
      background-image: url(../../assets/mobile-landscape/jobs-hero.png) !important;
      .parent {
        .phrase-1 {
          font-size: 3rem !important;
          line-height: 3.75rem !important;
        }
        .phrase-2 {
          font-size: 5.5rem !important;
          line-height: 5.5rem !important;
        }
        .phrase-3 {
          font-size: 1.75rem !important;
          line-height: 2rem !important;
        }
      }
    }
  }
}
