.form-fifth-section {
  display: none;
  hr {
    border: 1px solid #000;
    width: 100%;
    height: 1px;
    width: 79vw;
    margin-left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    border-color: #0095c6;
  }

  .upload-file {
    background: rgba(255, 255, 255, 0.5);
    border: 4px solid #782282;
    border-radius: 50px;
    cursor: pointer;
    .upload-file-text {
      margin: 0;
      margin-top: 5rem;
      margin-bottom: 5rem;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #782282;
      span {
        color: #164282;
      }
    }
  }
  .url-input-text {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #164282;
  }
  .url-parent {
    width: 100%;
    input {
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      border: 4px solid #0095c6;
      border-radius: 50px;
      height: 72px;
      box-sizing: border-box;
    }
  }
  .title {
    text-align: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #1d4480;
    height: unset;
    margin-bottom: 5rem;
  }
  .parent-form-knowledge {
    width: 100% !important;
    margin: auto !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .key-item {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-bottom: 2rem;
  }
  .tool-title {
    font-family: "Work Sans";
    font-style: "normal";
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #164282;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait) {
  .form-fifth-section {
    .title {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 21px !important;
      text-align: center !important;
      margin-bottom: 3rem !important;
      color: #1d4480 !important;
    }
    .parent-form-knowledge {
      width: 100% !important;
      margin: auto !important;
      display: flex !important;
      // flex-wrap: wrap !important;
      flex-direction: column;
    }
    .key-item {
      width: unset !important;
    }
    .image-tool {
      width: 42px !important;
      height: 49px !important;
    }
    .tool-title {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 19px !important;

      color: #1d4480;
    }
  }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape) {
  .form-fifth-section {
    .title {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 21px !important;
      text-align: center !important;
      margin-bottom: 3rem !important;
      color: #1d4480 !important;
    }
    .parent-form-knowledge {
      width: 100% !important;
      margin: auto !important;
      display: flex !important;
      justify-content: space-between;
      // flex-wrap: wrap !important;
      // flex-direction: column;
    }
    .key-item {
      width: unset !important;
    }
    .image-tool {
      width: 44.38px !important;
      height: 51.77px !important;
    }
    .tool-title {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
      line-height: 19px !important;

      color: #1d4480;
    }
  }
}
