.zailabContainer{
    box-sizing: border-box;
    width: 304px;
    height: 498px;
    background: #F2F3F3;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-family: 'Work Sans';
}

.descriptionZ ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color:#1D4480;
  }
  
  .descriptionZ ul i {
    font-style: normal;
    margin: 8px 0;
    
  }

  .zailabIcon{
    content: url(../../assets/mobile/ZailabLogo.png);
    margin-top:10px;
  }

  .buttonZ{ 
    margin-top:25px;
    margin-bottom: 33px;
  button{

    width: 154px;
    height: 36px;
    margin-top: 1%;
    background: linear-gradient(272.35deg, rgba(22, 66, 130, 0.5) 4.47%, rgba(0, 149, 198, 0.5) 105.38%);
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    border-radius: 50px;
    border: none;
    // font
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 0px;
    color: white;
    
}

}

.zIcon{
    position: relative;
    top: 3.6px;
    right:5px;
}

.shareZ{

    width: 100%;
    border-top: 1px solid #0095C6;
    margin-top: 0px;
    padding-top:25px;
    text-align: center;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1D4480;
}
.shareIcon{
    position: relative;
    top:4px;
    padding-left: 6px;
}


@media screen and (orientation: landscape) {
  @media (max-width: 1400px) and (min-width: 800px){
  // mobile landscape

      .zailabContainer{
        display: flex;
        flex-direction: row;
        width: 681px;
        height: 189px;
      }

      .descriptionZ ul {
      
        border-right: 1px solid #0095C6;
        // width: 100%;
        padding-right: 25px;
      }

      .descriptionZ ul i {
        font-style: normal;
        margin:5px 0;
        padding-left: 60px;
        white-space: nowrap;
      }
      .shareZ{
        // width: 100%;
        border: none;
    }

    .buttonZ{
      display: none;
    }

    .zailabLogo{
      padding-left: 40px;
    }

    .shareZ{
      padding:0 60px;
    }
  }
}
  


  @media (max-width: 2700px) and (min-width: 1400px)  {
    .zailabContainer{
      box-sizing: border-box;
      width: 424px;
      height: 859px;
      background: #F2F3F3;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      font-family: 'Work Sans';
  }
  
  .descriptionZ ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #164282;
      margin-top:32px;
    }
    
    .descriptionZ ul i {
      font-style: normal;
      margin: 8px 0;
      
    }
    .zailabLogo{
      display: flex;
      align-self: center;
      margin:0 auto;
    }

  
    .zailabIcon{
      content: url(../../assets/LogoZailab.png);
      
      margin-top:61px;
    }
    .buttonZ{
      margin-top: 56px;
      margin-bottom:56.5px;
      button{
  
        width: 272px;
        height: 64px;
        margin-top: 1%;
        background: linear-gradient(272.35deg, rgba(22, 66, 130, 0.5) 4.47%, rgba(0, 149, 198, 0.5) 105.38%);
        box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
        border-radius: 50px;
        border: none;
        // font
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: white;
        
    }
    }
  

  
  .zIcon{
      position: relative;
      top: 4px;
      right:18px;
      svg{
        width:24px;
        height: 24px;
      }
  }
  
  .shareZ{
      width: 100%;
      padding-top:5px;
      text-align: center;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #164282;
      
      .shareIcon{
        position: relative;
        top:10px;
        svg{
          margin-top: 30px;
          width: 40px;
          height: 40px;
        }
        
    }
  }
  

  }
