

//DESKTOP

.footer2{

    width: 100%;
    height: 100%;

    
}

.footerContainer{
  
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  font-family: 'Work Sans';
  color:#1D4480;
  background-color: #ffffff;
  background-image: linear-gradient(180deg, #ffffff 0%, #baeaff 100%);
  z-index:-3;

}

.mainTitle{
  text-transform:uppercase;
  display: flex;
  justify-content: center;
  margin:10px auto;
  // margin-left: 10%;
  
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */
  
  text-align: center;
  text-transform: uppercase;
}

.secondTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin:10px auto;
  // margin-left:25%;
  // padding:0 90px;

  font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* or 125% */

text-align: center;
letter-spacing: 0.01em;
}

.contactTitle{
  display: flex;
  justify-content: center;
  margin:10px auto;
  // margin-left:25%;
  font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 26px;
/* or 118% */

text-align: center;
/* or 129% */

text-align: center;
letter-spacing: 0.01em;

/* primary/dark */

color: #164282;
}

.footerTitle{
  font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* or 129% */

text-align: center;
letter-spacing: 0.01em;
  margin:0 90px;
  z-index:11;
}


.svgBG{
  width: 100%;
  background: url("../../assets/bottleVectorSvg.svg") 0 bottom no-repeat,
             url("../../assets/CLOUDS.svg") right bottom no-repeat;

            background-size:25%,45%;
}

.footerTitle{
  font-family: 'Work Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
/* or 129% */
margin-top: 10%;
text-align: center;
letter-spacing: 0.01em;
padding-bottom: 50px;
  
}





//BIGGER SCREEN DESKTOP
// @media only screen and (min-width: 2300px) {



//   .svgBG{
//     width: 100%;
//     background: url("../../assets/bottleVectorSvg.svg") 0px 435px no-repeat,
           
//                url("../../assets/Cloud2Desk.svg") 1950px 435px no-repeat,
//                url("../../assets/Cloud1Desk.svg") 1750px 355px no-repeat;
              
//   }
  
// }


   //DESKTOP RESPONSIVE

   @media (max-width: 2600px) and (min-width: 1200px)  {
    
    
    .mainTitle{
      text-transform:uppercase;
      display: flex;
      justify-content: start;
      margin-left: 13%;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 4%;
      /* identical to box height, or 120% */

      text-transform: uppercase;

      /* primary/dark */

      color: #164282;
    }

    .secondTitle{
      display: flex;
      justify-content: start;
      align-items: start;
      margin-left:25%;
      // padding:0 250px;
      text-align: start;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      /* or 125% */

      letter-spacing: 0.01em;

      /* primary/dark */

      color: #164282;
    }

    .contactTitle{
      display: flex;
      justify-content: start;
      margin-left:25%;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      /* or 119% */
      
      
      /* primary/dark */
      
      color: #164282;
    }
    
    .footerTitle{
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      /* or 120% */

      letter-spacing: 0.01em;

      color: #1D4480;
      
    }
    
 }
