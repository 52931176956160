.progress-container {
  width: 60%;
  margin: auto;
  position: relative;
  .progress-item {
    display: flex;
    justify-content: space-between;
    .line {
      position: absolute;
      left: 0;
      transition: width 1s linear;
      background-color: black;
      height: 2px;
      width: 0;
    }
    .progress-item-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 10;
      .progress-bubble {
        width: 40px;
        height: 40px;
        border: 3px solid rgba(0, 149, 198, 0.3);
        border-radius: 50%;
        flex: none;
        order: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f2f3f3;
        .progress-bubble-text {
          padding: 0;
          margin: 0;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          color: #0095c6;
          flex: none;
          order: 0;
          flex-grow: 0;
          opacity: 30%;
        }
        &.selected {
          filter: drop-shadow(0px 1px 4px rgba(0, 149, 198, 0.5));
        }
      }
      .progress-text {
        font-family: "Work Sans";
        margin-top: 1rem;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;
        color: #1d4480;
        justify-content: center;
      }
    }
  }
}

.progress-bubble.full {
  border: 3px solid rgba(0, 149, 198, 1);
}
.progress-bubble-text.full {
  opacity: 100%;
}

.footer-buttons {
  display: flex;
  justify-content: center;
}

.form-progressbar {
  height: 3px;
  position: absolute;
  top: 20px;
  background-color: #0095c6;
  width: 0%;
  opacity: 30%;
  &.full {
    width: 100%;
  }
}

.form-progressbar.sep2 {
  width: 25%;
  opacity: 100%;
}
.form-progressbar.sep3 {
  width: 50%;
  opacity: 100%;
}
.form-progressbar.sep4 {
  width: 75%;
  opacity: 100%;
}
.form-progressbar.sep5 {
  width: 100%;
  opacity: 100%;
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait) {
  .progress-container {
    width: 90%;
    .progress-item {
      .progress-bubble {
        width: 27.89px !important;
        height: 28px !important;
        &.selected {
          filter: drop-shadow(0px 1px 4px rgba(0, 149, 198, 0.5));
        }
      }
    }
    .form-progressbar {
      top: 13px;
    }
  }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape) {
  .progress-container {
    width: 80%;
    .progress-item {
      .progress-bubble {
        width: 27.89px !important;
        height: 28px !important;
        &.selected {
          filter: drop-shadow(0px 1px 4px rgba(0, 149, 198, 0.5));
        }
      }
    }
    .form-progressbar {
      top: 13px;
    }
  }
}

/* Styles for portrait tablet */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

/* Styles for landscape tablet */
@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
}
