::placeholder {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  // padding-left: 1rem;
  padding-right: 1rem;
}

.form-top {
  margin-top: 5rem;
}

.section-title {
  margin: 0;
  margin-bottom: 3rem;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #164282;
}

.container-form {
  height: 47rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background: #f2f3f3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding-top: 5.425rem;
  padding-bottom: 5.425rem;
  margin-bottom: 10rem;
}

.parent-form {
  width: 70%;
  margin: auto;
  height: 100%;
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

input {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding-left: 1rem;

  /* primary/dark */

  color: #164282;
}

.parent-button {
  text-align: center;
  margin-top: 2.45rem;
  margin-bottom: 2.45rem;
  margin-right: 2rem;
  .next-button {
    width: 160px;
    height: 64px;
    background: linear-gradient(
      272.35deg,
      rgba(22, 66, 130, 0.5) 4.47%,
      rgba(0, 149, 198, 0.5) 105.38%
    );
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    background: linear-gradient(272.35deg, #164282 4.47%, #0095c6 105.38%);
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    border-radius: 50px;
    border: none;
    // font
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 0px;
    color: white;
    cursor: pointer;
  }
  .previous-button {
    width: 160px;
    height: 64px;
    background: linear-gradient(
      272.35deg,
      rgba(22, 66, 130, 0.5) 4.47%,
      rgba(0, 149, 198, 0.5) 105.38%
    );
    box-shadow: 0px 2px 10px rgba(0, 149, 198, 0.5);
    border-radius: 50px;
    cursor: pointer;
  }
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait) {
  .form-top {
    margin-top: 0rem;
  }

  .parent-form {
    width: 80%;
    margin: auto;
  }
  .container-form {
    border-radius: 30px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  ::placeholder {
    font-size: 16px;
    line-height: 19px;
  }
  .section-title {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #1d4480;
    margin-bottom: 1rem;
  }
  .parent-button {
    .next-button {
      width: 103px;
      height: 36px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 0px;
    }
    .previous-button {
      width: 103px;
      height: 36px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 0px;
    }
  }
  .container-form {
    height: unset !important;
  }
}
/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape) {
  .form-top {
    margin-top: 0rem;
  }
  .container-form {
    height: unset !important;
  }
  .parent-form {
    width: 80%;
    margin: auto;
  }
  .container-form {
    border-radius: 30px;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  ::placeholder {
    font-size: 16px;
    line-height: 19px;
  }
  .section-title {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #1d4480;
    margin-bottom: 1rem;
  }
  .parent-button {
    .next-button {
      width: 103px;
      height: 36px;
      left: calc(50% - 103px / 2 + 55.5px);
      top: 2285px;

      background: linear-gradient(272.35deg, #164282 4.47%, #0095c6 105.38%);
      box-shadow: 0px 1px 8px rgba(0, 149, 198, 0.5);
      border-radius: 50px;
    }
    .previous-button {
      width: 103px;
      height: 36px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 0px;
    }
  }
}

/* Styles for portrait tablet */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}

/* Styles for landscape tablet */
@media (min-width: 1024px) and (max-width: 1279px) and (orientation: landscape) {
}
