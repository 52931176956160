.form-third-section {
  display: none;
  .upload-file {
    background: rgba(255, 255, 255, 0.5);
    border: 4px solid #782282;
    border-radius: 50px;
    cursor: pointer;
    .upload-file-text {
      margin: 0;
      margin-top: 5rem;
      margin-bottom: 5rem;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #782282;
      span {
        color: #164282;
      }
    }
  }
  .url-input-text {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #164282;
    &.errorMessage {
      color: red;
      font-size: 12px;
      margin-top: 0;
    }
  }
  .url-parent {
    width: 100%;
    textarea,
    input {
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      border: 4px solid #0095c6;
      border-radius: 50px;
      height: 72px;
      box-sizing: border-box;
    }
    textarea {
      padding-left: 1rem;
      padding-top: 1rem;
      border-radius: 20px;
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #164282;
      &.errorMessage {
        color: red;
        font-size: 12px;
        margin-top: 0;
      }
    }
  }
  .title {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #1d4480;
  }
}

/* Styles for portrait mobile */
@media screen and (min-width: 280px) and (max-width: 767px) and (orientation: portrait) {
  .form-third-section {
    display: none;
    .upper-part {
      .upload-file {
        background: rgba(255, 255, 255, 0.5);
        border: 2px solid #712583;
        border-radius: 20px;
        .upload-file-text {
          margin: 0;
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-align: center;

          color: #712583;
        }
      }
      .url-input-text {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1d4480;
        margin-top: 1rem;
        margin-bottom: 0.7rem;
        &.errorMessage {
          color: red;
          font-size: 12px;
          margin-top: 0;
        }

        &.first-url-input-text {
          margin-top: 0rem;
        }
      }
      .url-parent {
        width: 100%;
        textarea,
        input {
          width: 100%;
          background: rgba(255, 255, 255, 0.5);
          border: 2px solid #0095c6;
          border-radius: 20px;
          height: 48px;
          box-sizing: border-box;
        }
      }
    }
  }
}

/* Styles for landscape mobile */
@media (min-width: 481px) and (max-width: 992px) and (orientation: landscape) {
  .form-third-section {
    display: none;
    .upper-part {
      .upload-file {
        background: rgba(255, 255, 255, 0.5);
        border: 2px solid #712583;
        border-radius: 20px;
        .upload-file-text {
          margin: 0;
          margin-top: 2rem;
          margin-bottom: 2rem;
          font-family: "Work Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-align: center;

          color: #712583;
        }
      }
      .url-input-text {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1d4480;
        margin-top: 1rem;
        margin-bottom: 0.7rem;
        &.errorMessage {
          color: red;
          font-size: 12px;
          margin-top: 0;
        }

        &.first-url-input-text {
          margin-top: 0rem;
        }
      }
      .url-parent {
        width: 100%;
        textarea,
        input {
          width: 100%;
          background: rgba(255, 255, 255, 0.5);
          border: 2px solid #0095c6;
          border-radius: 20px;
          height: 48px;
          box-sizing: border-box;
        }
      }
    }
  }
}
