body {
  margin: 0;
  padding: 0;
  /* background: linear-gradient(169.7deg, rgb(2, 147, 197) 11.25%, rgb(23, 67, 130) 30.73%, rgb(32, 38, 81) 52.18%); */
}

@font-face {
  font-family: 'Work Sans';
  src: url('./fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
