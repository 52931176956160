.postContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
}

.postImg {
  content: url(../../assets/Logo-Codevelop-L.png);
}

.postLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  border-bottom: 1px solid #0095c6;
  width: 100%;
  padding-bottom: 50px;
}

.postTitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 60px;
  color: #1d4480;
}

.post_subtitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #1d4480;
}

.postText {
  margin-left: 7%;
  padding-bottom: 31px;
}

.full-width-border {
  border-bottom: 1px solid #0095c6;
  width: 100%;
  margin-bottom: 31px;
}

.postSvg {
  margin-right: 13.92px;
}

@media only screen and (max-width: 900px) {
  .postImg {
    width: 106.89px;
    height: 52px;
  }

  .postTitle {
    font-size: 28px;
    line-height: 32px;
  }

  .post_subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .postText {
    // margin:0 auto;
  }
  .postSvg {
    margin-right: 5.12px;
  }
}
